import styled from "styled-components"
import { darken } from "polished"
import colors from "shared/utils/colors"

export const ArticleContainer = styled.div`
  padding-left: ${props => props.paddingLeft}px;
`

export const Arrow = styled.i`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  transition: 0.2s transform ease;
  padding-right: 1rem;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.7rem;
  margin-top: -0.3rem;
  padding: 1rem;

  &[data-disabled="true"] {
    visibility: hidden;
    cursor: default;
  }

  &:hover {
    color: ${props => darken(0.1, props.theme.colors.primary)};
  }

  ${props =>
    props.open &&
    `
    transform: scaleY(-1);
  `}
`

export const ActionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center
  width: 16rem;
`

export const CountBox = styled.span`
  border-radius: 0.2rem;
  margin-left: 0.6rem;
  padding: 0.3rem 0.5rem;
  font-weight: 700;
  background: ${props => props.theme.colors.primary};
  color: white;
  font-size: 1rem;
`

export const Icon = styled.i`
  margin: 0 2px;
  cursor: pointer;

  &[data-disabled="true"] {
    cursor: not-allowed;
    color: ${colors.grayAthens};
  }
`

/* ************ Sorting Arrows ************ */

export const ArrowsContainer = styled.span`
  box-shadow: 0 0 3px ${colors.grayWhisper};
  border-radius: 3px;
  font-size: 1.2rem;
  padding: 0.4rem;
  display: flex;
  align-items: center;
`
export const DownContainer = styled.span`
  border-right: 1px solid ${colors.graySolitude};
  padding-right: 4px;
`

export const UpContainer = styled.span`
  border: none;
  margin-left: 4px;
`
